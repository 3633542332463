<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem v-if="!id">
          <span>客户名称：</span>
          <Select class="iviewIptWidth250 marginRight60" filterable v-model="supplierIndex" @on-change="clienChange">
            <Option v-for="(item, index) in clientNameList" :value="index" :key="item.customer_id + item.customer_type">{{ item.customer_name }}</Option>
          </Select>
        </FormItem>
        <FormItem v-else>
          <span>客户名称：</span>
          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight60" v-model="detailsInfo.customer_name" disabled></i-input>
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" :disabled="supplierIndex < 0 && !detailsInfo.customer_name" v-model="queryFrom.product_id" clearable filterable>
            <Option v-for="item in productList" :value="item.product_id" :key="item.product_id">{{ item.product_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250" @on-query-change="serachChange" :disabled="supplierIndex < 0 && !detailsInfo.customer_name" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
            <Option v-if="!LoadingSelect" v-for="(item, index) in modelList" :value="item.code_id" :key="index" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
            <Spin v-if="LoadingSelect">
              <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
              <div>Loading</div>
            </Spin>
          </Select>
        </FormItem>
        <FormItem>
          <span>选择仓库：</span>
          <Select class="iviewIptWidth250 marginRight60" v-model="queryFrom.WarehouseSelect" clearable filterable>
            <Option v-for="item in WarehouseSelectList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品" :loading="loading">
        <template slot-scope="{ row, index }" slot="product_unit_price">
          <div class="danjia">
            <span class="fl">¥ </span>
            <!--            <i-input placeholder="请输入" type="number" style="width: 100px;"  :value="row.product_unit_price" @on-change="changeIpt($event, 'product_unit_price', index)"></i-input>-->
            <!-- <InputNumber :max="999999999.99" :min="0" :precision="0" :value="row.product_unit_price" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'product_unit_price', index)"></InputNumber> -->
            <InputNumber :max="999999999.99" :min="0" :precision="2" :active-change="false" :value="row.product_unit_price" style="width: 100px;" @on-change="changeTotal($event, 'product_unit_price', index)"></InputNumber>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="sale_tax_rate">
          <div class="danjia">
            <InputNumber :max="999999999.99" :min="0" :precision="2" :value="row.sale_tax_rate" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'sale_tax_rate', index)"></InputNumber>
            <span class="fl">% </span>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="product_amount">
          <!--          <i-input placeholder="请输入" type="number" :value="row.product_amount" @on-change="changeIpt($event, 'product_amount', index)"></i-input>-->
          <InputNumber :max="999999" :min="0" :precision="0" :value="row.product_amount" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'product_amount', index)"></InputNumber>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0 && !loading">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <div class="summary" v-if="totleMoney > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totleMoney }}</span>
        </div>
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.remark"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnSure marginLeft20 fr" @click="save(1)">提交</span>
      <span class="pageBtn finger btnSure fr marginLeft20" @click="save(0)">保存</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1200" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :loading="serachLoading" :columns="chooseColumns" height="500" :data="chooseList" border @on-selection-change="selectChange"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="changeSupplierStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换客户名称？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureChangeSupplier">确定</span>
          <span class="pageBtn finger btnCancle" @click="cancleChangeSupplier">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
export default {
  name: 'salesOrderAdd',
  components: {
    TipsComponent,
  },
  computed: {
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.product_amount * 1
      })
      return totle
    },
    totleMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.product_total_price * 1
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      flag: true,
      clientNameList: [],
      changeSupplierStatus: false,
      id: '',
      supplierIndex: -1,
      saveIndex: '', // 保存上一次的index
      postStatus: false,
      deleteStatus: false,
      queryFrom: {
        handled_by: '',
        product_id: '',
        product_code_array: [],
        WarehouseSelect: '',
      },
      postFrom: {
        customer_info: {},
        product_info: [],
        remark: '',
      },
      productList: [],
      modelList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 180,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 180,
        },
        {
          title: '销售单价',
          slot: 'product_unit_price',
          align: 'center',
          width: 140,
        },
        {
          title: '销售税率',
          slot: 'sale_tax_rate',
          align: 'center',
          width: 140,
        },
        {
          title: '不含税价',
          key: 'product_after_tax_unit_price',
          align: 'center',
          width: 140,
          render: (h, param) => {
            return h('div', [h('span', (param.row.product_unit_price / (1 + param.row.sale_tax_rate / 100)).toFixed(4))])
          },
        },
        {
          title: '数量',
          slot: 'product_amount',
          align: 'center',
          width: 140,
        },
        {
          title: '余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 140,
        },
        // {
        //   title: '仓库余量',
        //   key: 'warehouse_inventory_quantity',
        //   align: 'center',
        //   width: 140,
        // },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 180,
        },
        {
          title: '有效天数',
          key: 'valid_days',
          align: 'center',
          width: 140,
        },
        {
          title: '小计',
          key: 'product_total_price',
          align: 'center',
          width: 180,
          render: (h, param) => {
            return h('div', [h('span', param.row.product_total_price ? '¥' + param.row.product_total_price : '')])
          },
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 140,
        },
        // {
        //   title: '仓库余量',
        //   key: 'warehouse_inventory_quantity',
        //   align: 'center',
        //   width: 140,
        // },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
        },
      ],
      hadSelectList: [], // 弹窗已选数据
      clickIndex: Number, // 暂存点击删除数据下标
      detailsInfo: {},
      WarehouseSelectList: [], // 仓库下拉
      serachLoading: false,
      LoadingSelect: false,
      loading: true,
    }
  },
  mounted() {
    this.querySupplierSelect()
    this.WarehouseSelect()
    this.id = this.$route.query.id || ''
    if (this.id) {
      this.supplierIndex = 1
      this.queryDetails(this.id)
    } else {
      this.loading = false
    }
  },
  methods: {
    // 规格信号模糊查询
    serachChange(e) {
      this.LoadingSelect = true
      this.$http.get(this.$api.productShow, { product_id: this.queryFrom.product_id, search_type: '3', customer_id: this.clientNameList[this.supplierIndex].customer_id, only_model: '1', model_keyword: e }, false).then(res => {
        this.modelList = res.data
        this.LoadingSelect = false
      })
    },

    back() {
      this.$router.go(-1)
    },
    // 选择仓库
    WarehouseSelect() {
      this.$http.get(this.$api.getWarehouseSelect, { page: 1, rows: 1000 }, false).then(res => {
        this.WarehouseSelectList = res.data
      })
    },
    // 查询客户名称列表
    querySupplierSelect() {
      this.$http.fetch(this.$api.customerList, false).then(res => {
        this.clientNameList = res.data
      })
    },
    // 查询详情
    queryDetails(id) {
      this.loading = true
      this.$http.get(this.$api.saleOrderDetails, { order_code: id }, true).then(res => {
        this.detailsInfo = res.data
        this.meterList = res.data.order_detail
        this.postFrom.remark = res.data.remark
        this.loading = false
        for (let i = 0; i < this.meterList.length; i++) {
          this.meterList[i].model_name = this.meterList[i].product_model_name
          this.meterList[i].code_id = this.meterList[i].product_code_id
          this.meterList[i].item_number = this.meterList[i].product_item_number
          this.meterList[i].name = this.meterList[i].product_name
          this.meterList[i].unit = this.meterList[i].product_unit
        }
        this.queryProduct('', this.detailsInfo.customer_id, this.detailsInfo.customer_type)
      })
    },
    // 客户名称改变
    clienChange(e) {
      // if (e >= 0) {
      //   this.queryFrom.product_id = ''
      //   this.queryFrom.product_code_array = []
      //   this.queryProduct('', this.clientNameList[e].customer_id, this.clientNameList[e].customer_type)
      // } else {
      //   this.queryFrom.product_id = ''
      //   this.queryFrom.product_code_array = []
      // }
      //  先判断有没有已选产品列表
      if (this.meterList.length > 0) {
        this.changeSupplierStatus = true
      } else {
        if (e >= 0) {
          this.queryFrom.product_id = ''
          this.queryFrom.product_code_array = []
          this.saveIndex = e
          this.queryProduct('', this.clientNameList[e].customer_id, this.clientNameList[e].customer_type, '1')
        } else {
          this.queryFrom.product_id = ''
          this.queryFrom.product_code_array = []
        }
      }
    },
    // 在已有产品的情况下 确认更换供应商
    sureChangeSupplier() {
      this.changeSupplierStatus = false
      this.queryFrom.product_id = ''
      this.queryFrom.product_code_array = []
      this.productList = []
      this.meterList = []
      this.queryProduct('', this.clientNameList[this.supplierIndex].customer_id, this.clientNameList[this.supplierIndex].customer_type)
    },
    // 在已有产品的情况下 取消更改供应商
    cancleChangeSupplier() {
      this.supplierIndex = this.saveIndex
      this.changeSupplierStatus = false
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.chooseStatus = false
      if (this.meterList.length > 0) {
        // let select = this.meterList.map(item => item.product_id + item.code_id)
        // this.hadSelectList = this.hadSelectList.filter(i => {
        //   return !select.includes(i.product_id + i.code_id)
        // })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
      }
      this.meterList.forEach((e, i) => {
        let num = this.meterList[i].product_amount ? this.meterList[i].product_amount : 0
        this.$set(this.meterList[i], 'product_amount', num)
        let str = Number(e.product_model_code.slice(1))
        this.$set(this.meterList[i], 'sign', str)
      })

      this.meterList.sort(function (a, b) {
        return a.sign - b.sign
      })
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (this.supplierIndex < 0) {
        this.$Message.warning('请先选择客户名称后再查询')
        return
      }
      if (this.queryFrom.product_code_array.length == 0 && !this.queryFrom.product_id) {
        this.$Message.warning('请先选择产品名称或规格型号后再查询')
        return
      }
      let query = {
        product_id: this.queryFrom.product_id,
        product_code_array: this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : '',
        search_type: '3',
        customer_id: this.clientNameList[this.supplierIndex].customer_id,
        customer_type: this.clientNameList[this.supplierIndex].customer_type,
        warehouse_id: this.queryFrom.WarehouseSelect,
      }
      this.$http.get(this.$api.productSearch, query, false).then(res => {
        if (res.data.length > 0) {
          this.chooseStatus = true
          this.serachLoading = true
          this.hadSelectList = []
          this.chooseList = res.data
          this.serachLoading = false
        } else {
          this.$Message.warning('该产品无库存')
        }
      })
    },
    // 查询产品
    queryProduct(id = null, customer_id) {
      // this.LoadingSelect = true
      if (id) {
        this.$http.get(this.$api.productShow, { product_id: id, search_type: '3', customer_id: customer_id }, false).then(res => {
          this.modelList = res.data
          this.LoadingSelect = false
        })
      } else {
        // this.$http.get(this.$api.productShow, { product_id: id, search_type: '3', customer_id: customer_id, only_model: '1' }, false).then(res => {
        //   this.modelList = res.data
        //   this.LoadingSelect = false
        // })
        this.$http.get(this.$api.productShow, { product_id: id, search_type: '3', customer_id: customer_id }, false).then(res => {
          this.productList = res.data
        })
      }
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = []
      // if (!e) return
      this.modelList = []
      this.queryProduct(e, this.clientNameList[this.supplierIndex].customer_id)
    },
    // 输入框改变
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
        return null
      }
      let str = e.target.value.trim().substring(0, 8)
      this.$set(this.meterList[index], name, str * 1)
      let total = this.meterList[index].product_amount * this.meterList[index].product_unit_price
      this.$set(this.meterList[index], 'product_total_price', total > 0 ? total.toFixed(2) : 0)
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
      let total = this.meterList[index].product_amount * this.meterList[index].product_unit_price
      this.$set(this.meterList[index], 'product_total_price', total > 0 && total ? total.toFixed(2) : 0)
    },
    // 保存
    save(num) {
      if (!this.supplierIndex < 0) {
        this.$Message.warning('请选择客户名称')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        this.meterList[i].product_after_tax_unit_price = this.meterList[i].product_unit_price / (1 + this.meterList[i].sale_tax_rate / 100)
        this.meterList[i].product_model_name = this.meterList[i].model_name
        this.meterList[i].product_code_id = this.meterList[i].code_id
        this.meterList[i].product_item_number = this.meterList[i].item_number
        this.meterList[i].product_name = this.meterList[i].name
        this.meterList[i].product_unit = this.meterList[i].unit
        if (!this.meterList[i].product_amount || !this.meterList[i].product_unit_price) {
          if (this.meterList[i].product_unit_price == 0) {
            this.$Message.warning(`单价不能填写为0，请修改为0.01`)
            return
          } else {
            this.$Message.warning(`请完善第${i + 1}组产品的单价或者数量`)
            return
          }
        }
      }
      if (!this.flag) return
      this.postFrom.product_info = JSON.parse(JSON.stringify(this.meterList))
      if (num) {
        this.postFrom.is_submit = '1'

        this.postStatus = true
        return
      }
      this.flag = false
      this.postFrom.is_submit = '0'
      if (this.id) {
        this.postFrom.order_code = this.id
        this.postFrom.customer_info = {
          customer_id: this.detailsInfo.customer_id,
          customer_type: this.detailsInfo.customer_type,
        }
        this.$http
          .put(this.$api.saleOrder, this.postFrom, true)
          .then(res => {
            this.flag = true
            this.$router.go(-1)
          })
          .catch(err => {
            this.flag = true
          })
        sessionStorage.setItem('updataCache', '0')
      } else {
        this.postFrom.customer_info = {
          customer_id: this.clientNameList[this.supplierIndex].customer_id,
          customer_type: this.clientNameList[this.supplierIndex].customer_type,
        }
        this.$http
          .post(this.$api.saleOrder, this.postFrom, true)
          .then(res => {
            this.flag = true
            this.$Message.success('保存成功')
            this.$router.go(-1)
          })
          .catch(err => {
            this.flag = true
          })
        sessionStorage.setItem('updataCache', '0')
      }
    },
    // 最终确认提交
    surePost() {
      this.flag = false
      this.postStatus = false
      if (this.id) {
        this.postFrom.customer_info = {
          customer_id: this.detailsInfo.customer_id,
          customer_type: this.detailsInfo.customer_type,
        }
        this.postFrom.order_code = this.id
        this.$http
          .put(this.$api.saleOrder, this.postFrom, true)
          .then(res => {
            this.flag = true
            this.$router.go(-1)
          })
          .catch(err => {
            this.flag = true
          })
      } else {
        this.postFrom.customer_info = {
          customer_id: this.clientNameList[this.supplierIndex].customer_id,
          customer_type: this.clientNameList[this.supplierIndex].customer_type,
        }
        this.$http
          .post(this.$api.saleOrder, this.postFrom, true)
          .then(res => {
            this.flag = true
            this.$Message.success('提交成功')
            this.$router.go(-1)
          })
          .catch(err => {
            this.flag = true
          })
      }
      sessionStorage.setItem('updataCache', '0')
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
